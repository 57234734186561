<template>
  <div class="settings-container">
    soon to come...
  </div>
</template>

<script>
export default {
  name: 'Settings',
}
</script>
